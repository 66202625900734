import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import DefaultLayout from "../layout"
import SEO from "../components/seo"

import "../assets/styles/components/page-modules.css"

class Page extends Component {
  componentDidMount() {
    document.body.classList.add('has-scrolled');

    window.addEventListener('scroll', () => {

      if (document.body.classList.contains('has-scrolled')) {
        if (window.scrollY === 0) {
          document.querySelector('#secondary-header').classList.remove('hidden');
        }
      }
    });
  }

  render() {
    const StaticPage = this.props.data.wordpressPage;

    return (
      <DefaultLayout className="singular-page">
        <SEO
          title={StaticPage.yoast.title}
          description={StaticPage.yoast.metadesc}
        />
        <div className="wrapper">
          <h1 className="page-title" dangerouslySetInnerHTML={{ __html: StaticPage.title }} />
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: StaticPage.content }} />
          <Link to="/" className="button">Back to Homepage</Link>
        </div>
      </DefaultLayout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      excerpt
      yoast {
        canonical
        title
        twitter_description
        twitter_image
        twitter_title
        redirect
        opengraph_title
        opengraph_image
        metakeywords
        opengraph_description
        metadesc
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        linkdex
        focuskw
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`